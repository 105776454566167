import { Text } from 'components/atoms';
import { ChatInput } from 'components/atoms/ChatInput';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { MessageBubble } from 'components/atoms/MessageBubble';
import { gaMeasurementId } from 'config';
import { selectCurrentConversation, selectSessionId } from 'features/chat/selector';
import { addChatToConversationAsync } from 'features/chat/thunks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { Conversation } from 'types';

ReactGA.initialize(gaMeasurementId);

const ChatSection: React.FC = () => {
  const { t } = useTranslation('gpt');
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const sessionId = useSelector(selectSessionId);
  const currentConversation = useSelector(selectCurrentConversation);
  const [conversationContext, setConversationContext] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [selectedModel, setSelectedModel] = useState('llama3-70b-8192');
  const [selectedDatabase, setSelectedDatabase] = useState('noDatabase');

  ReactGA.send({ hitType: 'pageview', page: '/gpt', title: 'GPT Demo' });

  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'heading2' : 'title1';

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        role: 'user',
        content: message,
      };
      setConversationContext((prevContext) => [...prevContext, newMessage]);
      setLoading(true);

      dispatch(
        addChatToConversationAsync({
          rag: true,
          sessionId: sessionId,
          prompt: message,
          model: selectedModel,
          database: selectedDatabase,
          graph: 'OFF',
        }),
      ).finally(() => {
        setLoading(false);
      });

      setMessage('');
    }
  };

  useEffect(() => {
    if (currentConversation) {
      setConversationContext(currentConversation);
    }
  }, [currentConversation, sessionId]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    setTimeout(scrollToBottom, 100);
  }, [conversationContext]);

  return (
    <Wrapper>
      <Container>
        {conversationContext.length == 0 && !loading && (
          <TextBox>
            <Text type={titleType}>{t('placeholder')}</Text>
          </TextBox>
        )}
        <Content>
          {conversationContext.map((msg, index) => (
            <MessageBubble key={index} sender={msg.role} text={msg.content}></MessageBubble>
          ))}
          {loading && (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
          <div ref={messagesEndRef} />
        </Content>
        <ChatInput
          message={message}
          setMessage={setMessage}
          onSendMessage={handleSendMessage}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          selectedDatabase={selectedDatabase}
          setSelectedDatabase={setSelectedDatabase}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin: 0 auto;
  width: 60%;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  justify-content: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    padding-left: 0px;
    justify-content: flex-start;
    padding: 5px;
    height: 85vh;
    overflow-y: hidden;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
  height: 80vh;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 5px;
    margin: 5px;
    flex-grow: 1;
    gap: 15px;
    max-height: calc(85vh - 100px);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.7);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.9); /* Darker on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(240, 240, 240, 0.5); /* Track color */
    border-radius: 10px;
  }

  /* Fallback for non-WebKit browsers */
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 100, 100, 0.7) rgba(240, 240, 240, 0.5); /* Thumb and track color */
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 20px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default ChatSection;
