import { Text } from 'components/atoms';
import DatabaseIcon from 'icons/Database';
import RAGSummaryIcon from 'icons/RAGSummary';
import SourceIcon from 'icons/Source';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

interface SourceDialogProps {
  open: boolean;
  onClose: () => void;
}

const HelpDialog: React.FC<SourceDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation('gpt');
  const [selectedOption, setSelectedOption] = useState<number>(0);

  if (!open) return null;

  const handleOptionClick = (index: number) => {
    setSelectedOption(index);
  };

  const optionText = [t('sourceInfo'), t('modelInfo'), t('databaseInfo'), t('summaryInfo')];

  const trainingQuestions = t('trainingQuestions', { returnObjects: true }) as string[];

  return (
    <>
      <Backdrop onClick={onClose} />
      <FloatingBox>
        <StyledTitle>
          <Text type={'label1normal'} weight="bold">
            {t('help')}
          </Text>
        </StyledTitle>
        <ButtonRow>
          <IconButton onClick={() => handleOptionClick(0)} active={selectedOption === 0}>
            <SourceIcon />
            <Text type={'body2reading'} textColor="lightBlack">
              {t('source')}
            </Text>
          </IconButton>
          <IconButton onClick={() => handleOptionClick(1)} active={selectedOption === 2}>
            <DatabaseIcon />
            <Text type={'body2reading'} textColor="lightBlack">
              {t('selectDatabase')}
            </Text>
          </IconButton>
          <IconButton onClick={() => handleOptionClick(2)} active={selectedOption === 3}>
            <RAGSummaryIcon />
            <Text type={'body2reading'} textColor="lightBlack">
              {t('getSummary')}
            </Text>
          </IconButton>
        </ButtonRow>
        <OptionText>
          <Text type="body2reading" textColor="lightBlack">
            {optionText[selectedOption]}
          </Text>
          {selectedOption === 2 &&
            trainingQuestions.map((question) => (
              <Text key={question} type={'body2reading'} textColor="lightBlack">
                {question}
              </Text>
            ))}
        </OptionText>
      </FloatingBox>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.backdrop};
  z-index: 999;
`;

const FloatingBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.entry};
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 700px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: left;
  background-color: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.blue};
  border-radius: 4px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--general-beta-08, 8px);
  align-self: stretch;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`;

const IconButton = styled.button<{ active: boolean }>`
  background: ${(props) => (props.active ? props.theme.colors.darkGrey : props.theme.colors.white)};
  border-radius: 8px;
  display: flex;
  padding: 4px var(---spacing-space3, 12px);
  justify-content: center;
  align-items: center;
  border: none;
  gap: 4px;

  &:hover {
    background: ${(props) => props.theme.colors.partialGrey};
  }
`;

const OptionText = styled.div`
  text-align: left;
`;

export default HelpDialog;
